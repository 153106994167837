import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './pages/About';
import References from './pages/References';
import CompletedProjects from './pages/CompletedProjects';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Footer from "./components/Footer";
import Home from "./pages/Home";

function App() {
    const [navbarHeight, setNavbarHeight] = useState(0);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const updateScreenSize = () => {
        setIsLargeScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenSize);
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (
        <Router>
            <div className="bg-stone-900 text-yellow-300 min-h-screen flex flex-col">
                <Navbar
                    onHeightChange={(height) => setNavbarHeight(height)}
                    onMenuToggle={(isOpen) => setIsMenuOpen(isOpen)}
                />
                <div
                    style={{
                        paddingTop: isLargeScreen ? 0 : navbarHeight,
                        marginLeft: isLargeScreen ? '16rem' : 0,
                    }}
                    className="flex-grow"
                >
                    <Routes>
                        <Route path="/" element={<Home isLargeScreen={isLargeScreen} isMenuOpen={isMenuOpen} />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/references" element={<References />} />
                        <Route path="/completed-projects" element={<CompletedProjects />} />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/careers" element={<Careers />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
