import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/pictures/onas.jpg';

// Funkce pro nahrazení mezery před jednoznakovými slovy a zabránění jejich zalomení
const preventOrphanWords = (text) => {
    return text.replace(/ ([ksvzaiouKSVZAIOU]) /g, ' $1\u00A0');
};

function Home({ isLargeScreen, isMenuOpen }) {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        setInfo([
            'Komplexní údržba a čištění pozemních komunikací a veřejného prostoru',
            '', '', '',
            'Marek Holub'
        ]);
    }, []);

    const shouldHideText = !isLargeScreen || isMenuOpen;

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-yellow-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0 flex flex-col justify-between"
                style={{ height: '98vh' }}
            >
                <article>
                    {info.map((paragraph, index) => (
                        <h1
                            key={index}
                            className={`mb-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center uppercase ${shouldHideText && index >= 3 ? 'hidden' : ''}`}
                        >
                            {preventOrphanWords(paragraph)}
                        </h1>
                    ))}
                </article>
                <div className="mt-4 md:mt-8 rounded-lg overflow-hidden relative">
                    <img src={backgroundImage} alt="Marek Holub" className="w-full h-auto" />
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-l from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-t from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                </div>
            </div>
        </div>
    );
}

export default Home;
