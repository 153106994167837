import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/pictures/kariera.jpg';

// Funkce pro nahrazení mezery před jednoznakovými slovy a zabránění jejich zalomení
const preventOrphanWords = (text) => {
    return text.replace(/ ([ksvzaiouKSVZAIOU]) /g, ' $1\u00A0');
};

function Careers() {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        setInfo([
            'Máte zájem stát se součástí naší rodinné firmy, kde klademe důraz na přátelskou atmosféru a vzájemnou podporu?',
            'Pokud ano, neváhejte a kontaktujte nás.',
            'Rádi vás přivítáme v našem kolektivu.'
        ]);
    }, []);

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900 font-opensans">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-yellow-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0 flex flex-col justify-between"
                style={{ height: '98vh' }}
            >
                <h1 className="text-2xl md:text-3xl lg:text-4xl mb-6">Kariéra</h1>
                <article>
                    {info.map((paragraph, index) => (
                        <p key={index} className="mb-4 text-lg sm:text-xl md:text-2xl">
                            {preventOrphanWords(paragraph)}
                        </p>
                    ))}
                </article>
                <div className="mt-8">
                    <Link to="/contact" className="text-blue-500 underline text-lg sm:text-xl md:text-2xl">
                        Kontaktujte nás
                    </Link>
                </div>
                <div className="mt-4 md:mt-8 rounded-lg overflow-hidden relative">
                    <img src={backgroundImage} alt="Kariéra" className="w-full h-auto" />
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-l from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-t from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                </div>
            </div>
        </div>
    );
}

export default Careers;
