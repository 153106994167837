import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/pictures/kontakt.jpg';

function Contact() {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        setInfo([
            'Marek Holub',
            'IČO 74344625',
            '+420 724 299 414',
            /*'marek.holub77@gmail.com'*/
        ]);
    }, []);

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900 font-opensans">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-yellow-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0 flex flex-col justify-between"
                style={{ height: '98vh' }}
            >                  <h1 className="text-2xl md:text-3xl lg:text-4xl mb-6">Kontakt</h1>
                {info.map((paragraph, index) => (
                    <p key={index} className="mb-4 text-lg sm:text-xl md:text-2xl">{paragraph}</p>
                ))}
                <p className="mb-4 text-lg sm:text-xl md:text-2xl">
                    <a href="mailto:info@holubmarek.cz" className="text-blue-500 underline">
                        info@holubmarek.cz
                    </a>
                </p>
                <div className="mt-4 md:mt-8 rounded-lg overflow-hidden relative">
                    <img src={backgroundImage} alt="Marek Holub" className="w-full h-auto" />
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-l from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-t from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
