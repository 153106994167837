import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/pictures/onas.jpg';
import Footer from "../components/Footer";

// Funkce pro nahrazení mezery před jednoznakovými slovy a zabránění jejich zalomení
const preventOrphanWords = (text) => {
    return text.replace(/ ([ksvzaiouKSVZAIOU]) /g, ' $1\u00A0');
};

function About() {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        setInfo([
            'Jsme rodinná firma, kterou v roce 2007 založil Marek Holub. Působíme převážně v Moravskoslezském kraji a naše práce je zaměřena na poskytování kvalitních služeb v oblasti běžné údržby pozemních komunikací a údržby veřejného prostranství.',
            'Specializujeme se na výkopové a zemní práce, sečení trávy, kácení stromů, demolice, odstraňování graffiti, odstraňování černých skládek a další.',
            'Naší prioritou je spolehlivost, preciznost a důraz na bezpečnost při každé zakázce. Díky našim dlouholetým zkušenostem a rodinnému přístupu ke každému projektu jsme si získali důvěru mnoha spokojených zákazníků.',
            'Pokud hledáte partnera, který se postará o vaše potřeby v oblasti údržby a terénních úprav, jste na správném místě.'
        ]);
    }, []);

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900 font-opensans">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-yellow-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0 flex flex-col justify-between"
                style={{ height: '98vh' }}
            >
                <h1 className="text-2xl md:text-3xl lg:text-4xl mb-6">O nás</h1>
                <article>
                    {info.map((paragraph, index) => (
                        <p key={index} className="mb-4 text-sm md:text-lg lg:text-xl">
                            {preventOrphanWords(paragraph)}
                        </p>
                    ))}
                </article>
                <div className="mt-4 md:mt-8 rounded-lg overflow-hidden relative">
                    <img src={backgroundImage} alt="Marek Holub údržba silnic a veřejných prostranství" className="w-full h-auto" />
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-l from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                    <div className="absolute inset-0 bg-gradient-to-t from-transparent via-transparent to-yellow-200 pointer-events-none"></div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;
