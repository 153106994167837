import React, { useState } from 'react';

function Footer() {
    const [showInfo, setShowInfo] = useState(false);

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    return (
        <div>
            <footer
                className="bg-transparent text-stone-900 p-4 fixed bottom-0 left-0 w-full text-center z-50 cursor-pointer"
                onClick={toggleInfo}
            >
                &copy; 2024 SC
            </footer>

            {showInfo && (
                <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 bg-stone-200 text-stone-900 p-4 border border-stone-900 rounded-lg shadow-lg z-50 font-kalam">
                    <p>Website created by</p>
                    <p>Ing. Šárka Číhalová, Ph.D.</p>
                    <p><a href="mailto:sarka.cihalova@centrum.cz">sarka.cihalova@centrum.cz</a></p>
                </div>
            )}
        </div>
    );
}

export default Footer;
