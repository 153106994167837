import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"; // Import navigace

// Funkce pro nahrazení mezery před jednoznakovými slovy a zabránění jejich zalomení
const preventOrphanWords = (text) => {
    return text.replace(/ ([ksvzaiouKSVZAIOU]) /g, ' $1\u00A0');
};

function Services() {
    const [info, setInfo] = useState([]);
    const navigate = useNavigate(); // Definování navigace

    useEffect(() => {
        setInfo([
            { name: 'Údržba pozemních komunikací', category: 'vse' },
            { name: 'Výkopové a zemní práce', category: 'vse' },
            { name: 'Odstraňování graffiti', category: 'odstraneni-graffiti' },
            { name: 'Demolice', category: 'demolice-zastavky' },
            { name: 'Sečení trávy', category: 'seceni-porostu' },
            { name: 'Kácení stromů', category: 'vse' },
            { name: 'Odstraňování černých skládek', category: 'cerna-skladka' },
            { name: 'Tlakové čištění', category: 'cisteni-strechy' },
            { name: 'Odklízení sněhu', category: 'odklizeni-snehu' },
        ]);
    }, []);

    const handleClick = (category) => {
        navigate(`/gallery?category=${category}`); // Přesměrování do galerie s kategorií
    };

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900 font-opensans">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-gradient-to-b from-yellow-300 to-stone-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0"
                style={{ height: '98vh' }}
            >
                <h1 className="text-2xl md:text-3xl lg:text-4xl mb-6">Naše služby</h1>
                <article>
                    {info.map((item, index) => (
                        <h1
                            key={index}
                            className="mb-4 text-lg sm:text-xl md:text-2xl cursor-pointer"
                            onClick={() => handleClick(item.category)} // Kliknutí přesměruje do galerie
                        >
                            {preventOrphanWords(item.name)}
                        </h1>
                    ))}
                </article>
            </div>
        </div>
    );
}

export default Services;
