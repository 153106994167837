import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/pictures/pozadiHolubMarek5.jpg';

function Navbar({ onHeightChange }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        const navbarElement = document.getElementById('navbar');
        if (navbarElement) {
            onHeightChange(navbarElement.offsetHeight);
        }
    }, [menuOpen, onHeightChange]);

    return (
        <nav
            id="navbar"
            className="bg-gradient-to-b from-yellow-300 to-stone-200 text-black w-full lg:w-64 h-auto lg:h-full p-4 fixed left-0 top-0 lg:block border-8 border-stone-900 rounded-3xl z-50 font-opensans"
        >
            <div className="lg:hidden flex justify-between items-center">
                <Link to="/" onClick={closeMenu}>
                    <span className="text-2xl font-bold">Marek Holub</span>
                </Link>
                <button
                    className="text-black focus:outline-none"
                    onClick={toggleMenu}
                >
                    <svg
                        className="w-8 h-8"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                    </svg>
                </button>
            </div>

            <div className={`${menuOpen ? 'block' : 'hidden'} lg:block`}>
                <div className="mb-4 lg:block hidden">
                    <Link to="/" onClick={closeMenu}>
                        <img src={backgroundImage} alt="Marek Holub" className="w-full h-auto rounded-lg" />
                    </Link>
                </div>
                <ul className="space-y-4">
                    {[
                        { name: 'O nás', path: '/about' },
                        { name: 'Naše služby', path: '/services' },
                        { name: 'Reference', path: '/references' },
                        { name: 'Dokončené realizace', path: '/completed-projects' },
                        { name: 'Fotogalerie', path: '/gallery' },
                        { name: 'Kariéra', path: '/careers' },
                        { name: 'Kontakt', path: '/contact' }
                    ].map((item, index) => (
                        <li key={index} className="border-2 border-stone-900 rounded-lg p-2 hover:bg-stone-900 hover:text-yellow-400 transition-colors duration-300">
                            <Link
                                to={item.path}
                                className="text-xl font-bold block w-full h-full"
                                onClick={closeMenu}
                            >
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
