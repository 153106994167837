import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';  // Import komponenty
import 'react-image-gallery/styles/css/image-gallery.css'; // Import stylů galerie
import thumbnailsData from '../components/ThumbnailsData';
import {useLocation} from "react-router-dom"; // Import dat

function Gallery() {
    const location = useLocation(); // Získání informací o aktuální URL
    const [selectedCategory, setSelectedCategory] = useState('vse');

    useEffect(() => {
        const params = new URLSearchParams(location.search); // Získání parametrů query string
        const category = params.get('category') || 'vse'; // Získání hodnoty kategorie
        setSelectedCategory(category); // Nastavení vybrané kategorie
    }, [location.search]); // Aktualizace při změně URL

    // Filtrování obrázků podle vybrané kategorie
    const filteredThumbnails = selectedCategory === 'vse'
        ? thumbnailsData
        : thumbnailsData.filter(thumb => thumb.category.includes(selectedCategory));

    // Přemapování na formát očekávaný ImageGallery komponentou
    const galleryItems = filteredThumbnails.map(thumb => ({
        original: thumb.src,
        thumbnail: thumb.src,
        description: thumb.alt,
        originalClass: 'custom-gallery-image' // Přidání vlastního stylu pro každý obrázek
    }));

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900 font-opensans">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-gradient-to-b from-yellow-300 to-stone-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0 flex flex-col justify-between"
                style={{ height: '98vh' }}
            >
                <h1 className="text-2xl md:text-3xl lg:text-4xl mb-6">Fotogalerie</h1>

                {/* Tlačítka pro výběr kategorie */}
                <div className="flex flex-wrap gap-2 mb-4 justify-center md:justify-start font-opensans">
                    {[
                        { name: 'Vše', path: 'vse' },
                        { name: 'Černá skládka', path: 'cerna-skladka' },
                        { name: 'Čištění propustku', path: 'cisteni-propustku' },
                        { name: 'Čištění střechy', path: 'cisteni-strechy' },
                        { name: 'Čištění žlabu', path: 'cisteni-zlabu' },
                        { name: 'Demolice zastávky', path: 'demolice-zastavky' },
                        { name: 'Odstranění graffiti', path: 'odstraneni-graffiti' },
                        { name: 'Odklízení sněhu', path: 'odklizeni-snehu' },
                        { name: 'Oprava dětského hřiště', path: 'oprava-detskeho-hriste' },
                        { name: 'Pokládka žlabu', path: 'pokladka-zlabu' },
                        { name: 'Sečení porostu', path: 'seceni-porostu' }
                    ].map((item, index) => (
                        <button
                            key={index}
                            onClick={() => setSelectedCategory(item.path)}
                            className="border border-yellow-200 rounded-md p-1 text-xs md:text-sm bg-yellow-200 text-stone-900 hover:bg-stone-900 hover:text-yellow-300 transition-colors duration-300 text-center font-opensans"
                            style={{ minWidth: '100px' }} // Nastavení minimální šířky tlačítek
                        >
                            {item.name}
                        </button>
                    ))}
                </div>

                {/* Vykreslení galerie */}
                <div className="flex-grow overflow-y-auto">
                    <ImageGallery items={galleryItems} />
                </div>
            </div>
        </div>
    );
}

export default Gallery;
