const thumbnailsData = [
    {
        category: ['cerna-skladka', 'vse'],
        src: '/pictures/cerna%20skladka%20pred%202021.jpg',
        alt: 'Černá skládka 2021'
    },
    {
        category: ['cerna-skladka', 'vse'],
        src: '/pictures/cerna%20skladka%20po%202021.jpg',
        alt: 'Odstraňování černé skládky 2021'
    },
    {
        category: ['cerna-skladka', 'vse'],
        src: '/pictures/cerna%20skladka%20pred%202020.jpg',
        alt: 'Černá skládka 2020'
    },
    {
        category: ['cerna-skladka', 'vse'],
        src: '/pictures/cerna%20skladka%20prubeh%202020.jpg',
        alt: 'Odstraňování černé skládky 2020'
    },
    {
        category: ['cerna-skladka', 'vse'],
        src: '/pictures/cerna%20skladka%20po%202020.jpg',
        alt: 'Odstraňování černé skládky 2020'
    },
    {
        category: ['cisteni-propustku', 'vse'],
        src: '/pictures/cisteni%20propustku%20prubeh%201%202022.jpg',
        alt: 'Čištění propustku 2022'
    },
    {
        category: ['cisteni-propustku', 'vse'],
        src: '/pictures/cisteni%20propustku%20prubeh%202%202022.jpg',
        alt: 'Čištění propustku 2022'
    },
    {
        category: ['cisteni-strechy', 'vse'],
        src: '/pictures/cisteni%20strechy%20prubeh%202024.jpg',
        alt: 'Čištění střechy 2024'
    },
    {
        category: ['cisteni-strechy', 'vse'],
        src: '/pictures/cisteni%20strechy%20po%202024.jpg',
        alt: 'Čištění střechy 2024'
    },
    {
        category: ['cisteni-zlabu', 'vse'],
        src: '/pictures/cisteni%20zlabu%202023.jpg',
        alt: 'Čištění žlabu 2023'
    },
    {
        category: ['demolice-zastavky', 'vse'],
        src: '/pictures/demolice%20zastavky%20prubeh%202023.jpg',
        alt: 'Demolice zastávky 2023'
    },
    {
        category: ['demolice-zastavky', 'vse'],
        src: '/pictures/demolice%20zastavky%20prubeh%202%202023.jpg',
        alt: 'Demolice zastávky 2023'
    },
    {
        category: ['odstraneni-graffiti', 'vse'],
        src: '/pictures/graffiti%20pred%202019.JPG',
        alt: 'Graffiti před odstraněním 2019'
    },
    {
        category: ['odstraneni-graffiti', 'vse'],
        src: '/pictures/graffiti%20po%202019.JPG',
        alt: 'Graffiti po odstranění 2019'
    },
    {
        category: ['odstraneni-graffiti', 'vse'],
        src: '/pictures/graffity%20pred%201%202019.JPG',
        alt: 'Graffiti před odstraněním 2019'
    },
    {
        category: ['odstraneni-graffiti', 'vse'],
        src: '/pictures/graffity%20po%201%202019.JPG',
        alt: 'Graffiti po odstranění 2019'
    },
    {
        category: ['odstraneni-graffiti', 'vse'],
        src: '/pictures/graffity%20pred%202%202019.JPG',
        alt: 'Graffiti před odstraněním 2019'
    },
    {
        category: ['odstraneni-graffiti', 'vse'],
        src: '/pictures/graffity%20po%202%202019.JPG',
        alt: 'Graffiti po odstranění 2019'
    },
    {
        category: ['odklizeni-snehu', 'vse'],
        src: '/pictures/odklizeni%20snehu%20pred%202023.jpg',
        alt: 'Úklid sněhu - parkoviště ŘSD Ostrava 2023'
    },
    {
        category: ['odklizeni-snehu', 'vse'],
        src: '/pictures/odklizeni%20snehu%20po%202023.jpg',
        alt: 'Úklid sněhu - parkoviště ŘSD Ostrava 2023'
    },
    {
        category: ['oprava-detskeho-hriste', 'vse'],
        src: '/pictures/oprava%20detskeho%20hriste%20pred%202023.jpg',
        alt: 'Revitalizace dětského hřiště 2023'
    },
    {
        category: ['oprava-detskeho-hriste', 'vse'],
        src: '/pictures/oprava%20detskeho%20hriste%20po%202023.jpg',
        alt: 'Revitalizace dětského hřiště 2023'
    },
    {
        category: ['pokladka-zlabu', 'vse'],
        src: '/pictures/pokladka%20zlabu%20pred%202023.jpg',
        alt: 'Pokládka žlabu 2023'
    },
    {
        category: ['pokladka-zlabu', 'vse'],
        src: '/pictures/pokladka%20zlabu%20prubeh%202023.jpg',
        alt: 'Pokládka žlabu 2023'
    },
    {
        category: ['seceni-porostu', 'vse'],
        src: '/pictures/seceni%20travy%202023.jpg',
        alt: 'Sečení porostu 2023'
    }
];

export default thumbnailsData;
