import React, { useState, useEffect } from 'react';

function CompletedProjects() {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        setInfo([
            'Stránka se připravuje.'
            /*'ŘSD, Povodí Odry, Ostravské komunikace.'*/
        ]);
    }, []);

    return (
        <div className="relative p-2 md:p-2 min-h-screen bg-stone-900 font-opensans">
            <div
                className="relative z-10 text-black font-bold p-4 md:p-8 rounded-2xl shadow-md bg-gradient-to-b from-yellow-300 to-stone-200 max-w-full md:max-w-[90%] lg:max-w-[1200px] ml-0 lg:ml-0"
                style={{ height: '98vh' }}
            >
                <h1 className="text-2xl md:text-3xl lg:text-4xl mb-6">Dokončené realizace</h1>
                <div>
                    {info.map((paragraph, index) => (
                        <p key={index} className="mb-4 text-lg sm:text-xl md:text-2xl">{paragraph}</p>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CompletedProjects;
